import React from 'react';
import './Welcome.css'
import "react-quill/dist/quill.snow.css";

function Welcome() {


    return (
        <div className="Welcome">
            <div className={"SayingBG"}>

                <div className="Saying">
                   <span className="SayingBody">
                                          “ Pour en percer tous les secrets, une vie entière n’y suffirait pas…<br/>
                    C’est avec enthousiasme et contemplation que j’entre dans ce monde afin d’en explorer l’immensité.<br/>
                    Un art fait de peu de choses et qui appelle à une constante humilité.<br/>
                    Les végétaux sont nos matériaux et les mains nos outils principaux.<br/>
                    Ajoutons à cela un brin de créativité et une pointe de rigueur pour que naissent nos créations.<br/>
                    Je vous invite alors à explorer avec moi, et pas à pas, ce vaste héritage qu’est la vannerie.”<br/>

                   </span>

                    <span className="Sign"> Léna Michardière, artisane vannière.</span>

                </div>
            </div>



        </div>
    );
}

export default Welcome;
