import React from 'react';
import './Rent.css'
import "react-quill/dist/quill.snow.css";

function Rent() {


    return (
        <div className="Rent">
               <div className="TitleCreation">
               De la décoration de mariage sera prochainement proposée à la location.
            </div>
            {/*<div className="RentContainer">
                <div className="RentTitle">
                    <div>
                        <p>"Ensemble n°1"</p>
                        <p> Mariage / Pacs </p>
                    </div>
                    <div>
                    </div>
                </div>
                <img className="RentImage" style={{flex: 1}} src={crea1}/>

                <div className="RentText" style={{flex: 1}}>
                    <p>Composition</p>
                    <p>de 35 € à 90 €</p>
                </div>
            </div>   <div className="RentContainer">
                <div className="RentTitle">
                    <div>
                        <p>"Ensemble n°1"</p>
                        <p> Mariage / Pacs </p>
                    </div>
                    <div>
                    </div>
                </div>
                <img className="RentImage" style={{flex: 1}} src={crea1}/>

                <div className="RentText" style={{flex: 1}}>
                    <p>Composition</p>
                    <p>de 35 € à 90 €</p>
                </div>
            </div>*/}


        </div>
    );
}

export default Rent;
