import {atom} from "recoil";

const todoListState = atom({
    key: 'todoListState',
    default: [],
});

export const adminAtom = atom({
    key : 'adminAtom',
    default:''
})
export const animAtom = atom({
    key : 'animAtom',
    default:true
})
export const loggedAtom = atom({
    key : 'loggedAtom',
    default:''
})
export const zoomedAtom = atom({
    key : 'zoomedAtom',
    default:''
})