import React, {useState} from 'react';
import ReactQuill from "react-quill";
import './Contact.css'
import "react-quill/dist/quill.snow.css";
import {urlencoded} from "express";
import {Util} from "leaflet";
import trim = Util.trim;

function Contact() {


    const [value, setValue] = useState('');

    const myColors = [
        "purple",
        "#785412",
        "#452632",
        "#856325",
        "#963254",
        "#254563",
        "white"
    ];
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [{ color: myColors }],
            [{ background: myColors }]
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align"
    ];

    const [message, setMessage] = useState(
        ""
    );
    const [subject, setSubject] = useState(
        ""
    );
    const [from, setFrom] = useState(
        ""
    );
    const handleProcedureContentChange = (content: any) => {
        setMessage(content);
    };
        const send =async ()=>{
            try{
                const headers =new Headers();
                headers.append('Content-Type','application/json')
                await fetch("/api/sendContact",{method:'POST',headers,body:JSON.stringify({message:message,from,subject})})

                alert("Message envoyé avec succès !")
            }catch (e){

            }

        }
    return (
        <div className="Contact">
            <div className="SubTitle" > Formulaire de contact </div>
            <form onSubmit={(e)=>{
                e.preventDefault();
              send()
            }} >
                <div className="InputLine">
                    <label className="InputAndLabel"  htmlFor="contact email">Votre adresse email</label>
                    <input className="InputAndLabel" value={from} onChange={({target:{value}})=>{setFrom((value))}}  id="contact_email" placeholder="mon@email.com" type={"email"} />
                </div>
                <div className="InputLine">
                    <label className="InputAndLabel"  htmlFor="subject">Sujet </label>
                    <input className="InputAndLabel"  value={subject} onChange={({target:{value}})=>{setSubject((value))}} id="subject" placeholder={"Mon sujet"} type={"text"} />
                </div>
                <div className="InputLine" style={{flexDirection:"column"}} >
                    <label className="InputAndLabel"  htmlFor="contact_message">Votre message</label>
                    <ReactQuill
                        id="contact_message"
                        // theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder={"Votre message"}
                        value={message}
                        onChange={handleProcedureContentChange}
                    />
                </div>
                <div >
                    <input  disabled={trim(message)===""} className="InputAndLabel" type="submit" value={"Envoyer"} />
                </div>
            </form>

        </div>
    );
}

export default Contact;
