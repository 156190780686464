import React, {ReactNode} from 'react';
import './Internship.css'
import "react-quill/dist/quill.snow.css";
import {Link} from "react-router-dom";
import {useRecoilState} from "recoil";
import {zoomedAtom} from "../atoms";

const WorkShop = ({
                      image,
                      title,
                      subtitle,
                      description,

                  }: {
    image?: { url: string, title: string },
    title?: ReactNode,
    subtitle: ReactNode,
    description: ReactNode

}) => {
    const [, setZoomed] = useRecoilState(zoomedAtom);
    return <> {title && <p className="WorkShopTitle">{title}</p>}<div className="WorkShopElement">
        <div className="WorkShopText">

            <p className="WorkShopSubTitle">{subtitle}</p>
            <div className="WorkShopImageWrapper">{image&&<img  onClick={()=>setZoomed(image.url)} alt={image.title} src={image.url}/>}</div>
            <p>{description}</p>
        </div>
    </div></>;

}

function Internship() {


    return (
        <div className="Internship">
            <h1>ATELIERS DE VANNERIE A COISE-SAINT-JEAN-PIED-GAUTHIER</h1>


            <h2>Chaque mois sont organisés environ un atelier pour adultes et un atelier pour enfants.</h2>

            <div className="InternshipSpecialText"><span >Ces activités étant organisées par l’association <b>Art’Artouille</b>, une <b>cotisation annuelle de 5
                euros</b> est à régler pour y participer.</span> <span style={{textAlign:'center'}}> <a href='https://www.facebook.com/p/ArtAtouille-100057263057505/'><img style={{mixBlendMode: 'multiply', width: '100px'}}
                                                                                src={'/arttatouille.jpeg'}/></a></span></div>

            <p>Pour vous inscrire ou pour plus d'informations vous pouvez m'envoyer un message (<Link style={{fontSize:'18px'}} to={'/contact'}>contact</Link>) ou m'appeler au <a style={{fontSize:'18px'}} href='tel:0644921850'><u>06 44 92 18 50</u></a></p>
            <p>Le prix d’un atelier pour adultes est de <b>40 euros pour 3h</b>, celui pour enfants est de <b>35 euros pour 3h</b>.
                Ces tarifs comprennent la récolte et la préparation de la matière première et le prêt des outils. Une
                collation sera offerte aux participant.e.s qui repartiront chacun.e avec l’objet qui aura été
                réalisé.</p>

            <p>Un atelier adultes peut accueillir jusqu’à 8 personnes pour des techniques simples, jusqu’à 6 adultes si
                les notions abordées sont plus complexes.</p>

            <p>Un atelier enfants peut accueillir jusqu’à 6 personnes, participation en autonomie à partir de l’âge de 7 ans. Chaque enfant étant différent, vous pouvez me contacter pour échanger sur vos
                souhaits, que ce soit pour l’accompagner après 7 ans ou pour y participer avec lui ou elle avant cet
                âge.</p>


            <WorkShop
                title={<>Ateliers du mois d'octobre 2024 :</>}
                subtitle={<>Atelier adultes : <b>samedi 26 octobre</b> de 9h à 12h</>}
                description={<>Création d'une corbeille allongée. Matériaux : Cornouiller sanguin et clématite sauvages
                    principalement. Autres végétaux possibles en fonction de la récolte</>}
                image={{url: '/st_corb_all.jpeg', title: 'corbeille allongée'}}

            /> <WorkShop
            subtitle={<>Atelier enfants : <b>mercredi 30 octobre</b> de 9h à 12h</>}
            description={<> Création d'un pot à crayons. Matériaux: Cornouiller sanguin et clématite sauvages
                principalement. Autres végétaux possibles en fonction de la récolte</>}
            image={{url: '/st_pot_cray.jpeg', title: 'Pot a crayon'}}

        />


            <WorkShop
                title={<>Ateliers de mois de novembre 2024 :</>}
                subtitle={<>Atelier adultes : <b>samedi 23 novembre</b> de 9h à 12h</>}
                description={<>Création d'une mangeoire à oiseaux. Cornouiller sanguin et clématite sauvages
                    principalement. Autres végétaux possibles en fonction de la récolte</>}
                image={{url: '/ws_nv_2.jpeg', title: 'mangeoire à oiseaux'}}
            /> <WorkShop
                subtitle={<>Atelier enfants : <b> mercredi 13 novembre</b> de 9h à 12h</>}
                description={<>Création d'une mangeoire à oiseaux. Cornouiller sanguin et clématite sauvages
                    principalement. Autres végétaux possibles en fonction de la récolte</>}

                image={{url: '/ws_nv_1.jpeg', title: 'mangeoire à oiseaux enfant'}}

            />

            <WorkShop
                title={<>Ateliers du mois de décembre :</>}
                subtitle={<>Atelier adultes : <b>dimanche 8 décembre</b> de 9h à 12h</>}
                description={<>Création de décorations de Noël en osier : Sapin de Noël, anneau, en suspensions individuelles ou guirlandes.</>}
                image={{url: '/deco_noel.jpeg', title: 'deco noel'}}


            /> <WorkShop
                subtitle={<>Atelier enfants : <b> mercredi 4 décembre</b> de 9h à 12h</>}
                description={<>Création de décorations de Noël en osier : Sapin de Noël, anneau, en suspensions individuelles ou guirlandes. Si temps le permet : étoiles.</>}
                image={{url: '/deco_noel.jpeg', title: 'mangeoire à oiseaux enfant'}}

            />



        </div>
    );
}

export default Internship;
